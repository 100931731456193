import * as document from 'document'; // eslint-disable-line import/no-unresolved, import/no-extraneous-dependencies, import/no-unresolved
import {$, $$} from '../../../../../../../assets/scripts/utilities/dom-selection';
import {setInitialFocus, removeFocus} from '../../../../../../../assets/scripts/utilities/dom-focusing';

export const navMain = {
  init: () => {
    const navItems = $$('[data-nav-main-item]');
    const linkSelector = '[data-nav-main-item-link]';
    const contentSelector = '[data-nav-main-item-content]';

    const activate = item => {
      item.setAttribute('aria-expanded', true);
    };
    const deActivate = item => {
      item.setAttribute('aria-expanded', false);
    };

    const onOutsideFocus = (item, e) => {
      if (!item.contains(e.target)) {
        deActivate(item);
      }
    };

    const onOutsideFocusAll = e => {
      navItems.forEach(item => {
        onOutsideFocus(item, e);
      });
    };

    const setOutsideHandlers = () => {
      document.body.addEventListener('focus', onOutsideFocusAll, true);
      document.body.addEventListener('click', onOutsideFocusAll, true);
    };
    const removeOutsideHandlers = () => {
      document.body.removeEventListener('focus', onOutsideFocusAll, true);
      document.body.removeEventListener('click', onOutsideFocusAll, true);
    };

    const onMouseOver = e => {
      const navItem = e.currentTarget;
      activate(navItem);
      navItem.removeEventListener('mouseover', onMouseOver, true);
      navItem.addEventListener('mouseout', onMouseOut, true);
    };

    const onMouseOut = e => {
      const navItem = e.currentTarget;
      deActivate(e.currentTarget);
      navItem.addEventListener('mouseover', onMouseOver, true);
      navItem.removeEventListener('mouseout', onMouseOut, true);
    };

    const showAndSetFocus = (item, content) => {
      activate(item);
      setTimeout(() => {
        setInitialFocus(content);
        setOutsideHandlers();
      }, 0);
    };

    const hideAndRemoveFocus = (item, content) => {
      deActivate(item);
      setTimeout(() => {
        removeFocus(content);
        removeOutsideHandlers();
      }, 0);
    };

    const onKeyup = (item, link, content, e) => {
      if (e.which === 13 && e.target === link) {
        showAndSetFocus(item, content);
      }
      if (e.which === 27) {
        hideAndRemoveFocus(item, content);
      }
    };

    navItems.forEach(navItem => {
      navItem.addEventListener('mouseover', onMouseOver, true);

      const navLink = $(linkSelector, navItem);
      const navContent = $(contentSelector, navItem);

      document.addEventListener('keyup', onKeyup.bind(null, navItem, navLink, navContent), false);
    });
  }
};
