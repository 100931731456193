import {$, $$} from '../../../assets/scripts/utilities/dom-selection';

const initGallery = gallery => {
  const toolbar = $('[role="toolbar"]', gallery);
  const listbox = $('[role="listbox"]', gallery);
  const forwards = $('[data-progress="1"]', toolbar);
  const backwards = $('[data-progress="-1"]', toolbar);

  if (!gallery && !toolbar) {
    return;
  }

  const getOptionId = option => {
    return option.getAttribute('id');
  };

  const activate = el => {
    const activeId = getOptionId(el);
    listbox.setAttribute('aria-activedescendant', activeId);
    el.setAttribute('aria-selected', true);
  };

  const deactivate = el => {
    el.removeAttribute('aria-selected');
  };

  const sendToBackOfList = el => {
    listbox.appendChild(el);
  };

  const bringToFrontOfList = el => {
    listbox.insertBefore(el, listbox.children[0]);
  };

  const goForwards = () => {
    const currentEl = listbox.children[0];
    const nextEl = listbox.children[1];
    deactivate(currentEl);
    sendToBackOfList(currentEl);
    activate(nextEl);
  };

  const goBackwards = () => {
    const currentEl = listbox.children[0];
    const nextEl = listbox.children[listbox.children.length - 1];
    deactivate(currentEl);
    activate(nextEl);
    bringToFrontOfList(nextEl);
  };

  forwards.addEventListener('click', e => {
    e.preventDefault();
    goForwards();
  });

  backwards.addEventListener('click', e => {
    e.preventDefault();
    goBackwards();
  });

  activate(listbox.children[0]);
  toolbar.removeAttribute('aria-hidden');
};

export const gallery = {
  init: (selector = '[data-component-gallery]') => {
    console.log('Gallery behaviour loaded');
    const galleries = $$(selector);

    if (!galleries || galleries.length === 0) {
      return;
    }
    galleries.forEach(initGallery);
  }
};
