import {$, $$} from '../../../assets/scripts/utilities/dom-selection';
import * as document from 'document'; // eslint-disable-line import/no-unresolved, import/no-extraneous-dependencies, import/no-unresolved

const maxNewChildren = 3;

const replaceAttr = (attr, search, replace, el) => {
  const currValue = el.getAttribute(attr);
  const newValue = currValue.replace(search, replace);
  el.setAttribute(attr, newValue);
};

const getButtonTemplate = index => {
  let frag = document.createDocumentFragment();
  let tmp = document.createElement('body');
  let child;

  tmp.innerHTML =
  `<button class="Button"
      id="admission-form-button-remove-child-${index}"
      type="button"
      aria-label="Close child ${index + 1} form">
      <svg class="Icon Icon--max" aria-hidden="true">
        <title id="close-icon-title">chevron-left</title>
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/assets/icons/sprite.svg#close-icon"></use>
      </svg>
    </button>`;
  while (child = tmp.firstChild) {
    frag.appendChild(child);
  }
  tmp = null;
  return frag;
};

let index = 0;

export const admissionForm = {
  init: () => {
    console.log('Admission Form behaviour loaded');
    const childRegions = $$('[id*="child-info-fieldset"]');
    const endRegion = $('#registration-info-fieldset');
    if (!childRegions || childRegions.length === 0) {
      return;
    }
    const parentNode = endRegion.parentNode;

    let oTrigger = $('#admission-form-button-add-child');
    let lastChildRegion = childRegions[0];

    const cloneChildRegion = e => {
      const lastChildFieldset = $('fieldset', lastChildRegion);
      const lastChildRegionReference = lastChildRegion;
      const nTrigger = lastChildFieldset.removeChild(oTrigger);
      const newChildRegion = lastChildRegion.cloneNode(true);
      const newChildFieldset = $('fieldset', newChildRegion);
      const removeChildFrag = getButtonTemplate(index + 1);
      const replaceId = replaceAttr.bind(null, 'id', `-${index}`, `-${index + 1}`);
      const replaceFor = replaceAttr.bind(null, 'for', `-${index}`, `-${index + 1}`);
      const replaceName = replaceAttr.bind(null, 'name', `-${index}`, `-${index + 1}`);
      $$('[id]', newChildRegion).forEach(replaceId);
      $$('[for]', newChildRegion).forEach(replaceFor);
      $$('[name]', newChildRegion).forEach(replaceName);

      replaceId(newChildRegion);
      const removeChildButton = [].slice.call(removeChildFrag.childNodes, 0)[0];

      removeChildButton.addEventListener('click', e => {
        lastChildFieldset.appendChild(nTrigger);
        parentNode.removeChild(newChildRegion);
        oTrigger = nTrigger;
        lastChildRegion = lastChildRegionReference;
        index--;
      });
      newChildFieldset.appendChild(removeChildFrag);

      nTrigger.addEventListener('click', cloneChildRegion); // re-bind lost event listener
      if (index < (maxNewChildren - 1)) {
        newChildFieldset.appendChild(nTrigger);
      }

      parentNode.insertBefore(newChildRegion, endRegion);
      lastChildRegion = newChildRegion;
      index++;
    };

    oTrigger.addEventListener('click', cloneChildRegion);
  }
};
