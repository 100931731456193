import {$, $$} from '../../../assets/scripts/utilities/dom-selection';

export const buttonToggle = {
  init: (selector = 'button[aria-pressed][aria-controls]') => {
    console.log('Button toggle behaviour loaded');
    const toggles = $$(selector);

    const getTargetId = el => {
      return el.getAttribute('aria-controls');
    };
    const getControlTarget = el => {
      const targetId = getTargetId(el);
      if (targetId) {
        return $(`#${targetId}`);
      }
      return null;
    };

    const hasControlTarget = el => {
      return Boolean(getControlTarget(el));
    };

    const getPressedText = el => {
      return el.getAttribute('data-pressed-text');
    };

    const getUnpressedText = el => {
      return el.getAttribute('data-unpressed-text');
    };

    const regions = toggles
    .filter(hasControlTarget)
    .map((button, index) => {
      return {
        button: button,
        target: getControlTarget(button),
        index: index,
        targetId: getTargetId(button),
        buttonText: {}
      };
    });

    const setButtonInactive = button => {
      button.setAttribute('aria-pressed', 'false');
      const unpressedText = getUnpressedText(button);
      const pressedText = getPressedText(button);
      if (unpressedText && pressedText) {
        const inner = $('[data-text]', button).firstChild;
        inner.textContent = unpressedText;
      }
    };

    const setButtonActive = button => {
      button.setAttribute('aria-pressed', 'true');
      const unpressedText = getUnpressedText(button);
      const pressedText = getPressedText(button);
      if (unpressedText && pressedText) {
        const inner = $('[data-text]', button).firstChild;
        inner.textContent = pressedText;
      }
    };

    const getLinkedActiveButtons = targetId => {
      return $$(`button[aria-pressed="true"][aria-controls="${targetId}"]`);
    };

    const getLinkedInactiveButtons = targetId => {
      return $$(`button[aria-pressed="false"][aria-controls="${targetId}"]`);
    };

    const hideRegion = ({target, targetId, button}) => {
      target.setAttribute('aria-hidden', 'true');
      setButtonInactive(button);
      getLinkedActiveButtons(targetId).forEach(setButtonInactive);
    };

    const showRegion = ({target, targetId, button}) => {
      target.removeAttribute('aria-hidden');
      setButtonActive(button);
      getLinkedInactiveButtons(targetId).forEach(setButtonActive);
    };

    const buttonIsInactive = target => {
      return target.getAttribute('aria-pressed') === 'false';
    };

    const toggleRegion = region => {
      if (buttonIsInactive(region.button)) {
        showRegion(region);
      } else {
        hideRegion(region);
      }
    };

    const initRegion = region => {
      if (buttonIsInactive(region.button)) {
        hideRegion(region);
      } else {
        showRegion(region);
      }
    };

    const onClick = (region, e) => {
      e.preventDefault();
      toggleRegion(region);
    };

    regions.forEach(region => {
      initRegion(region);
      region.button.addEventListener('click', onClick.bind(null, region));
    });
  }
};
