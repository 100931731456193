// import * as document from 'document'; // eslint-disable-line import/no-unresolved, import/no-extraneous-dependencies, import/no-unresolved
import {$, $$} from '../../../assets/scripts/utilities/dom-selection';

export const notification = {
  init: () => {
    console.log(`Notification behaviour loaded`);
    const notifications = $$('[data-notification]');
    const onClick = (notification, index, e) => {
      const button = e.currentTarget;
      notification.setAttribute('aria-hidden', true);
      button.removeEventListener('click', handlers[index]);
    };

    const handlers = notifications.map((notification, index) =>
      onClick.bind(null, notification, index));

    let i = notifications.length;

    while (--i > -1) {
      const notification = notifications[i];
      const button = $('[data-notification-button]', notification);
      button.addEventListener('click', handlers[i]);
    }
  }
};
