import * as document from 'document'; // eslint-disable-line import/no-unresolved, import/no-extraneous-dependencies, import/no-unresolved
import {$} from '../../../../../../assets/scripts/utilities/dom-selection';

import {safeActiveElement, setInitialFocus, removeFocus, bindKeypress, maintainFocus} from '../../../../../../assets/scripts/utilities/dom-focusing';

export const mastheadSs = {
  init: () => {
    console.log(`Small Screen Menu behaviour loaded`);
    const body = $('body');
    const show = $('[data-show-menu-ss]');
    const hide = $('[data-hide-menu-ss]');
    const menu = $('[data-menu-ss]');

    let previousFocusedElement;

    if (!(show || hide) || !menu) {
      return console.warn(`
Small Screen Menu, or menu activation buttons, not present on page.
Exiting Small Screen Menu behaviour.`);
    }

    const handleKeyDown = e => {
      bindKeypress(true, hideMenu, menu, e);
    };

    const hideMenu = () => {
      menu.classList.remove('s-is-active');
      body.classList.remove('menu-ss-is-active');

      setTimeout(() => {
        document.removeEventListener('keydown', handleKeyDown);
        document.body.removeEventListener('focus', handleMaintainFocus, true);
        removeFocus(menu);
        if (previousFocusedElement) {
          previousFocusedElement.focus();
        }
      }, 0);
    };

    const handleMaintainFocus = e => {
      maintainFocus(true, menu, e);
    };

    const showMenu = () => {
      menu.classList.add('s-is-active');
      body.classList.add('menu-ss-is-active');

      setTimeout(() => {
        previousFocusedElement = safeActiveElement();
        setInitialFocus(menu);
        document.addEventListener('keydown', handleKeyDown);
        document.body.addEventListener('focus', handleMaintainFocus, true);
      }, 0);
    };

    const onShowClick = e => {
      e.preventDefault();
      showMenu();
    };

    const onHideClick = e => {
      e.preventDefault();
      hideMenu();
    };

    if (show) {
      show.addEventListener('click', onShowClick);
    }
    if (hide) {
      hide.addEventListener('click', onHideClick);
    }
  }
};
