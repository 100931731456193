import {$} from '../../../../../../assets/scripts/utilities/dom-selection';

export const mastheadSearch = {
  init: () => {
    console.log(`Masthead Search behaviour loaded`);
    const root = $('[data-masthead-nav-secondary-root]');
    const nav = $('[data-masthead-nav-secondary]', root);

    const parent = $('[data-mastheadsearch]', root);
    if (!parent) {
      return null;
    }
    const link = $('[data-mastheadsearch-link]', parent);
    const input = $('[data-mastheadsearch-input]', parent);

    const onClick = e => {
      e.preventDefault();
      if (parent.classList.contains('s-is-active')) {
        hideInput(e);
      } else {
        showInput(e);
      }
    };

    const onKeyDown = e => {
      if (e.which === 27) {
        hideInput(e);
        e.preventDefault();
      }
    };

    const showInput = e => {
      parent.classList.add('s-is-active');
      nav.setAttribute('aria-hidden', true);
      setTimeout(() => {
        input.focus();
        parent.addEventListener('focusout', hideInput, true);
        parent.addEventListener('keydown', onKeyDown);
      }, 0);
    };

    const hideInput = e => {
      setTimeout(() => {
        parent.classList.remove('s-is-active');
        nav.removeAttribute('aria-hidden');
        link.focus();
        parent.removeEventListener('focusout', hideInput, true);
        parent.removeEventListener('keydown', onKeyDown);
      }, 10);
    };

    link.addEventListener('click', onClick);
  }
};
