import {
  $,
  $$
} from '../../../../assets/scripts/utilities/dom-selection';

// This script also controls accordions, as on a structual and accessibility level they function
// almost identically
export const tabs = {
  init: (selector = '[role="tab"]:not([data-role-variant])', isAccordion = false) => {
    console.log(`Tabs behaviour loaded${isAccordion ? `, variant is: 'Accordion'` : `, variant is: 'Tabs'`}`);
    let regionMatchHandler;

    const tabs = $$(selector);
    if (!tabs || tabs.length === 0) {
      return;
    }

    const getTabTarget = tab => {
      const panelId = tab.getAttribute('aria-controls');
      if (panelId) {
        return $(`#${panelId}`);
      }
      return null;
    };

    const regions = tabs.map((tab, index) => {
      return {
        link: $('a', tab),
        tab: tab,
        panel: getTabTarget(tab),
        index: index
      };
    });

    const panelIsActive = panel => {
      return !panelIsInactive(panel);
    };
    const panelIsInactive = panel => {
      return panel.hasAttribute('aria-hidden');
    };
    const tabIsActive = tab => {
      return tab.hasAttribute('aria-selected');
    };
    const tabIsInactive = tab => {
      return !tabIsActive(tab);
    };
    const regionIsActive = region => {
      return panelIsActive(region.panel) || tabIsActive(region.tab);
    };
    const regionIsInactive = region => {
      return panelIsInactive(region.panel) || tabIsInactive(region.tab);
    };

    const hideRegion = ({
      panel,
      tab,
      link
    }) => {
      tab.removeAttribute('aria-selected');
      tab.setAttribute('aria-expanded', false);
      panel.setAttribute('aria-hidden', true);
      panel.setAttribute('tabindex', -1);
    };

    const showRegion = ({
      panel,
      tab,
      link
    }) => {
      panel.removeAttribute('aria-hidden');
      panel.setAttribute('tabindex', 0);
      tab.setAttribute('aria-selected', true);
      tab.setAttribute('aria-expanded', true);
    };

    const toggleRegion = region => {
      if (regionIsInactive(region)) {
        showRegion(region);
      } else {
        hideRegion(region);
      }
    };

    regionMatchHandler = showRegion;
    if (isAccordion) {
      regionMatchHandler = toggleRegion;
    }

    const activateRegionOnLoad = regions => {
      const activeRegions = regions.filter(regionIsActive);
      const activeRegion = activeRegions[0] || regions[0];
      regions.forEach(hideRegion);
      showRegion(activeRegion);
    };

    const clickHandler = (currentRegion, region) => {
      if (region.link === currentRegion.link) {
        regionMatchHandler(region);
      } else {
        hideRegion(region);
      }
    };

    const onClick = (region, e) => {
      e.preventDefault();
      regions.forEach(clickHandler.bind(null, region));
    };

    regions.forEach(region => {
      region.link.addEventListener('click', onClick.bind(null, region));
    });

    activateRegionOnLoad(regions);
  }

};
