import {$$} from '../../../../../../../assets/scripts/utilities/dom-selection';

export const mastheadSsNavMain = {
  init: (selector = '[data-masthead-ss-title-link]') => {
    console.log(`MastheadSs Nav-main behaviour loaded`);
    const links = $$(selector);
    if (!links) {
      return;
    }

    const onClick = e => {
      e.preventDefault();
      const link = e.currentTarget;
      link.classList.toggle('s-is-active');
    };

    links.forEach(link => {
      link.addEventListener('click', onClick);
    });
  }
};
