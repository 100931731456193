import * as window from 'window'; // eslint-disable-line import/no-unresolved, import/no-extraneous-dependencies, import/no-unresolved
import {$$} from '../../../assets/scripts/utilities/dom-selection';

const init = (google, root, options) => {
  if (!google) {
    throw new Error('Instance of Google maps API not provided to initMaps');
  }

  const selector = (options && options.selector) || '[data-component-map]';
  const dataAttr = (options && options.dataAttr) || 'data-component-map';
  const el = root || window.document;

  const mapEls = $$(selector, el);
  
  const maps = Array.prototype.slice.call(mapEls).map(mapEl => {
    const mapData = JSON.parse(mapEl.getAttribute(dataAttr));
    return createMap(google, mapEl, mapData);
  });
  return maps;
};

const createMarker = (map, showOnLoad, result, status) => {
  let infoWindow = new google.maps.InfoWindow();

  if (status !== google.maps.places.PlacesServiceStatus.OK) {
    console.warn('Maps PlacesService error:', status);
    return;
  }

  let marker = new google.maps.Marker({
    map: map,
    position: result.geometry.location
  });

  let address = result.adr_address;
  let newAddr = address.split('</span>,').join('</span>,</br>');

  infoWindow.setContent(`<strong>${result.name}</strong><br>${newAddr}`);
  if (showOnLoad) infoWindow.open(map, marker);
  marker.addListener('click', function () {
    infoWindow.open(map, marker);
  });

  return marker;
};

const createMap = (google, element, options) => {
  if (!element) {
    throw new Error('No DOM element supplied to createMap');
  }
  
  const zoom = parseInt(options.zoom, 10) || 15;
  const placeIds = [].concat(options.placeId);
  const center = options.center || { 'lat': 50.821967, 'lng': -0.119954 }
  
  const map = new google.maps.Map(element, {
    center: options.center ,
    scrollwheel: false,
    zoom: zoom
  });

  const service = new google.maps.places.PlacesService(map);
  const createMarkerOnResult = createMarker.bind(null, map, placeIds.length===1);

  if (placeIds.length > 0) {
    placeIds.forEach(placeId => {
      service.getDetails({ placeId: placeId }, createMarkerOnResult);
    });    
  } 

  google.maps.event.addDomListener(window, 'resize', function () {
    var center = map.getCenter();
    google.maps.event.trigger(map, 'resize');
    map.setCenter(center);
  });

  return {
    map: map
  };
}; 

export const googleMap = {
  init: init,
  createMap: createMap
};
